import { css } from '@emotion/core'
import { mq } from './Layout.styles'

export const cultureBps = css(
  mq({
    marginTop: [34.5, 35, 32, 52]
  })
)

export const jobRoleBps = css(
  mq({
    marginLeft: [0, 0, 0, 122],
    marginRight: [0, 0, 0, 122]
  })
)

export const jobItemBps = css(
  mq({
    marginTop: [50, 50, 0, 0],
    marginBottom: [50, 50, 0, 0]
  })
)

export const H4Bps = css(
  mq({
    fontSize: [33.6],
    color: ['white'],
    fontWeight: ['bold'],
    textAlign: ['center'],
    maxWidth: [280, 280, 504, 504]
  })
)

export const H6Bps = css(
  mq({
    fontSize: [19.6],
    fontWeight: ['bold'],
    width: ['100%']
  })
)

export const PBps = css(
  mq({
    fontSize: [14],
    maxWidth: [312, 312, 312, 312],
    textAlign: ['center'],
    color: ['rgba(0, 0, 0, 0.87)'],
    margin: ['0 auto', '0 auto']
  })
)

export const highlightBoxBps = css(
  mq({
    width: [328, '100%', 680, 680],
    marginLeft: [16, 0, 200, 429],
    marginRight: [16, 0, 200, 429],
    display: ['flex', 'flex', 'flex', 'flex'],
    alignItems: ['center', 'center', 'center', 'center']
  })
)

export const PHighlightBps = css(
  mq({
    maxWidth: [280, 374, 504, 504],
    textAlign: ['center']
  })
)

export const dividerBorderBps = css(
  mq({
    width: [200, 200, 328, 328],
    marginBottom: [24, 24, 23.5, 23.5]
  })
)

export const PheadginContentBps = css(
  mq({
    fontSize: [14],
    maxWidth: [328, '100%', 680, 680]
  })
)
export const ImgHeadginContentBps = css(
  mq({
    width: [300]
  })
)

export const styledCulture = css`
  ${cultureBps};
`

export const headignContent = css`
  display: flex;
  img {
    ${ImgHeadginContentBps};
    margin: 0 auto;
    height: 240px;
  }
  p {
    ${PheadginContentBps};
    line-height: 1.71;
    margin: 0 auto;
  }
`

export const highlightBox = css`
  ${highlightBoxBps};
  margin: 0 auto !important;
  flex-direction: column;
  background: #9013fe;
  border-radius: 16px;
  img {
    margin-top: 50px;
    width: 56px;
  }
  h4 {
    ${H4Bps};
    margin-top: 16px;
    margin-bottom: 24px;
  }
  p {
    ${PHighlightBps};
    color: #f9fafa;
    line-height: 1.71;
    margin-bottom: 50px;
  }
`

export const jobRole = css`
  ${jobRoleBps};
  h6 {
    ${H6Bps};
    text-align: center;
    padding-bottom: 4px;
    color: rgba(0, 0, 0, 0.87);
  }
  span {
    width: 100%;
    display: flex;
    justify-content: center;
    font-size: 14px;
    line-height: 1.71;
    text-align: center;
    color: rgba(0, 0, 0, 0.6);
    padding-bottom: 16px;
  }
  p {
    ${PBps};
    line-height: 1.71;
  }
`
export const dividerBorder = css`
  opacity: 0.5;
  border-bottom: solid 1px #f9fafa;
  height: 1px;
  ${dividerBorderBps};
`